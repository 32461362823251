import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Sitemark from "./SitemarkIcon";
import { BorderAllTwoTone } from "@mui/icons-material";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 0,
        width: "100%", // Ensures the AppBar spans the full width
      }}
    >
      {/* Removed Container for full-width AppBar */}
      <StyledToolbar variant="dense" disableGutters sx={{ backgroundColor: "primary.main", opacity: 0.97 }}>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}>
          <p style={{ marginRight: 50, color: "#dae121" }}>MEALMATE</p>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button variant="text" color="white" size="small" sx={{ fontWeight: "300" }}>
              Features
            </Button>
            <Button variant="text" color="white" size="small" sx={{ fontWeight: "300" }}>
              Testimonials
            </Button>
            <Button variant="text" color="white" size="small" sx={{ fontWeight: "300" }}>
              Highlights
            </Button>
            <Button variant="text" color="white" size="small" sx={{ fontWeight: "300" }}>
              Pricing
            </Button>
            <Button variant="text" color="white" size="small" sx={{ minWidth: 0, fontWeight: "300" }}>
              FAQ
            </Button>
            <Button variant="text" color="white" size="small" sx={{ minWidth: 0, fontWeight: "300" }}>
              Blog
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 1,
            alignItems: "center",
          }}
        >
          <Button
            color="secondary.main"
            variant="text"
            size="small"
            sx={{
              textTransform: "none",
              fontWeight: "300",
            }}
          >
            Sign in
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            sx={{
              textTransform: "none",
              background: "transparent",
              border: "0px",
              boxShadow: "none",
              color: "secondary.main",
              fontWeight: "300",
            }}
          >
            Sign up
          </Button>
        </Box>
        <Box sx={{ display: { sm: "flex", md: "none" } }}>
          <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
            <Box sx={{ p: 2, backgroundColor: "background.default" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
              <Divider sx={{ my: 3 }} />
              <MenuItem>Features</MenuItem>
              <MenuItem>Testimonials</MenuItem>
              <MenuItem>Highlights</MenuItem>
              <MenuItem>Pricing</MenuItem>
              <MenuItem>FAQ</MenuItem>
              <MenuItem>Blog</MenuItem>
              <MenuItem>
                <Button color="primary" variant="contained" fullWidth>
                  Sign up
                </Button>
              </MenuItem>
              <MenuItem>
                <Button color="primary" variant="outlined" fullWidth>
                  Sign in
                </Button>
              </MenuItem>
            </Box>
          </Drawer>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
}

const styles = {};
