/* global anime, Letterize */

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import anime from "animejs"; // Import anime.js
import Letterize from "letterizejs"; // Import Letterize

// Styled component for the `#DAE121` mask element
const MaskBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "30%", // Takes up 40% of the Hero component's height
  backgroundColor: "#DAE121",
  clipPath: "polygon(0 0, 100% 0, 100% calc(100% - 200px), 0 100%)", // Creates the cut-off effect
  zIndex: 1, // Positioned above the background but below text content
  display: "flex", // Ensures content fills the box
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: `url(${"/static/screenshots/material-ui/getting-started/templates/dashboard.jpg"})`,
  backgroundSize: "cover",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    backgroundImage: `url(${"/static/screenshots/material-ui/getting-started/templates/dashboard-dark.jpg"})`,
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  useEffect(() => {
    // Initialize Letterize for text animation
    const letterize = new Letterize({
      targets: ".animate-me",
    });

    // Set up anime.js animation timeline
    const animation = anime.timeline({
      targets: letterize.listAll,
      delay: anime.stagger(100, {
        grid: [letterize.list[0].length, letterize.list.length],
        from: "center",
      }),
      loop: true,
    });

    animation
      .add({
        letterSpacing: "25px",
        duration: 0,
      })
      .add({
        letterSpacing: "35px",
      })
      .add({
        letterSpacing: "25px",
      });
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        position: "relative",
        backgroundRepeat: "no-repeat",
        backgroundImage: "radial-gradient(ellipse 100% 50% at 50% -20%, hsl(62deg 76% 50% / 50%), transparent)",
        height: "100vh", // Full viewport height
        overflow: "hidden", // Ensures any overflowing content is hidden
        ...theme.applyStyles("dark", {
          backgroundImage: "radial-gradient(ellipse 100% 50% at 50% -20%, hsl(62deg 76% 50% / 50%), transparent)",
        }),
      })}
    >
      {/* Mask with `#DAE121` color and bottom cut-off effect */}
      <MaskBox
        style={{
          paddingTop: "60px",
          color: "rgba(45, 49, 39, .2)",
          display: "flex",
          textTransform: "uppercase",
          lineHeight: "2.7",
          fontWeight: "200",
        }}
      >
        <div style={{ rotate: "1.5deg" }}>
          {/* Animated text content */}
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
          <div className="animate-me" style={{}}>
            MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE _ MEALMATE
          </div>
        </div>
      </MaskBox>

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          zIndex: 2, // Ensures content is above the mask
          position: "relative", // To maintain content layering
          border: "1px solid rgba(46, 49, 45, 0.10)",
          borderTop: 0,
          borderBottom: 0,
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
              color: "white",
              marginBottom: "15px",
            }}
          >
            Our&nbsp;latest&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              products
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            Explore our cutting-edge dashboard, delivering high-quality solutions tailored to your needs. Elevate your experience with top-tier features and
            services.
          </Typography>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={1} useFlexGap sx={{ pt: 2, width: { xs: "100%", sm: "350px" } }}>
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <TextField
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              fullWidth
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                },
              }}
            />
            <Button variant="contained" color="primary" size="small" sx={{ minWidth: "fit-content" }}>
              Start now
            </Button>
          </Stack>
          <Typography variant="caption" color="text.secondary" sx={{ textAlign: "center" }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}
